<template>
  <div>
    <div class="breadCurmb mt-3">
      <label>
        <router-link to="/">{{ $t("Home") }}</router-link>
      </label>
      <span>/</span>
      <label active>{{ $t("Reports") }}</label>
    </div>

    <v-toolbar-title class="mt-3">التقارير</v-toolbar-title>

    <v-container fluid>
      <v-row>
        <v-col md="3">
          <div
            class="form-group"
            :class="{
              'has-error': errors.has('addEditValidation.report'),
            }"
          >
            <div class="mt-2">
              <label class="control-label">اسم التقرير</label>
            </div>
            <v-autocomplete
              class="d-block mt-2"
              name="report"
              data-vv-scope="addEditValidation"
              v-validate="'required'"
              :data-vv-as="$t('Report Name')"
              hide-details
              dense
              outlined
              v-model="addEditObj.report"
              item-value="id"
              item-text="name"
              :items="listReport"
              clearable
            ></v-autocomplete>
            <div
              class="help-block"
              v-if="errors.has('addEditValidation.report')"
            >
              {{ errors.first("addEditValidation.report") }}
            </div>
          </div>
        </v-col>

        <!-- <v-col md="2" class="mt-2" v-if="addEditObj.report == 4">
          <div
            class="form-group"
          >
            <label class="control-label">{{ $t("Page") }}</label>
            <div>
              <el-input
                type="number"
                min="1"
                name="page"
                class="form-control mt-2"
                style="width: 100%"
                v-model="addEditObj.page"
              ></el-input>
            </div>
          </div>
        </v-col> -->

        <v-col md="3" class="mt-2" v-if="addEditObj.report !== 4">
          <div
            class="form-group"
            :class="{
              'has-error': errors.has('addEditValidation.cooking_date'),
            }"
          >
            <label class="control-label">{{ $t("Date") }}</label>
            <div>
              <el-date-picker
                type="date"
                name="cooking_date"
                class="form-control mt-2"
                style="width: 100%"
                v-validate="'required'"
                data-vv-scope="addEditValidation"
                :data-vv-as="$t('Cooking Date')"
                v-model="addEditObj.date"
              ></el-date-picker>
            </div>
            <div
              class="help-block"
              v-if="errors.has('addEditValidation.cooking_date')"
            >
              {{ errors.first("addEditValidation.cooking_date") }}
            </div>
          </div>
        </v-col>

        <v-col md="4" class="mt-9">
          <el-button @click="downloadExcel" type="success"
            >تصدير اكسل
          </el-button>
        </v-col>
      </v-row>
      <v-row v-loading="loading" class="mt-5"></v-row>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
               user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : [],
      addEditObj: {
        date: null,
        report: null,
        page: 1,
      },
      loading: false,
      listReport: [],
    };
  },
  methods: {
    downloadPDF() {},
    downloadExcel() {
      if (this.addEditObj.report == 1) {
        let send = {
          date: moment(this.addEditObj.date).format("YYYY-MM-DD"),
        };
        this.loading = true;
        this.$store
          .dispatch(`report/export_kitchen_today_export`, send)
          .then(() => {
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.notifyCustomError(this.$t("Error"), error);
          })
          .finally(() => {
            this.loading = false;
          });
      } else if (this.addEditObj.report == 2) {
        let send = {
          date: moment(this.addEditObj.date).format("YYYY-MM-DD"),
        };
        this.loading = true;
        this.$store
          .dispatch(`report/export_deliveries_export`, send)
          .then(() => {
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.notifyCustomError(this.$t("Error"), error);
          })
          .finally(() => {
            this.loading = false;
          });
      } else if (this.addEditObj.report == 3) {
        let send = {
          date: moment(this.addEditObj.date).format("YYYY-MM-DD"),
        };
        this.loading = true;
        this.$store
          .dispatch(`report/export_quantities_today`, send)
          .then(() => {
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.notifyCustomError(this.$t("Error"), error);
          })
          .finally(() => {
            this.loading = false;
          });
      } else if (this.addEditObj.report == 4) {
        // let send = {
        //   page: this.addEditObj.page
        // };
        this.loading = true;
        this.$store
          .dispatch(`report/export_subscription_data`)
          .then(() => {
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.notifyCustomError(this.$t("Error"), error);
          })
          .finally(() => {
            this.loading = false;
          });
      } else if (this.addEditObj.report == 5) {
        let send = {
          date: moment(this.addEditObj.date).format("YYYY-MM-DD"),
        };
        this.loading = true;
        this.$store
          .dispatch(`report/export_renwal_data_export`, send)
          .then(() => {
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.notifyCustomError(this.$t("Error"), error);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  mounted() {
    if (
      this.user &&
      this.user.permissions &&
      this.user.permissions.includes("export_kitchen_today")
    ) {
      this.listReport.push({
        id: 1,
        name: this.$t("CookingToday"),
      });
    } 
     if (
      this.user &&
      this.user.permissions &&
      this.user.permissions.includes("export_deliveries")
    ) {
      this.listReport.push({
        id: 2,
        name: this.$t("DelegateToday"),
      });
    } 
     if (
      this.user &&
      this.user.permissions &&
      this.user.permissions.includes("export_quantities_today")
    ) {
      this.listReport.push({
        id: 3,
        name: this.$t("QuantitiesToday"),
      });
    } 
     if (
      this.user &&
      this.user.permissions &&
      this.user.permissions.includes("export_subscription_data")
    ) {
      this.listReport.push({
        id: 4,
        name: this.$t("SubscribtionsData"),
      });
    } 
     if (
      this.user &&
      this.user.permissions &&
      this.user.permissions.includes("export_renwal_data")
    ) {
      this.listReport.push({
        id: 5,
        name: this.$t("ResumeToday"),
      });
    }
  },
};
</script>
